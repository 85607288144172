<template>
  <FormContent :selected="selected" class="pa-6" background-color="transparent">
    <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
    </v-col>
    <DataCrud
      v-if="form.files.length > 0"
      :column-decorators="decorators"
      :table-header="header"
      :tableData="[...form.files].sort((a, b) => a.index - b.index)"
      :justify-center="false"
      :enableAddFunction="!readOnly"
      :enableRemoveFunction="!readOnly"
      :showFormDialog="true"
      :server-items-length="form.files.length"
    >
    </DataCrud>
    <div v-else>
      <span style="color: rgb(108, 117, 125); caret-color: rgb(108, 117, 125);">
        <p>
          Este produto ainda não possui imagens cadastradas.
        </p>
      </span>
    </div>
  </FormContent>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import DataCrud from '../../../components/template/crud/DataCrud';

export default {
  components: {
    FormContent,
    DataCrud,
  },
  props: {
    idProductType: {
      type: [Object, String],
    },
    form: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Imagens',
    },
    mask: { type: Object, default: () => ({}) },
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      productType: null,
      selected: false,
      item: {
        index: {},
        url: {},
        main: false,
      },
      decorators: [
        {
          column: 'main',
          iconFunction: (item) => {
            if (item.main) {
              return 'mdi-check';
            }
            return 'mdi-cancel';
          },
          iconColorFunction: (item) => {
            if (item.main) {
              return '#02D13F';
            }
            return 'orange';
          },
        },
      ],
      header: [
        { text: 'Índice', value: 'index' },
        { text: 'URL', value: 'url' },
        { text: 'Principal', value: 'main' },
      ],
    };
  },
  watch: {
    idProductType: {
      handler() {
        this.init();
      },
    },
  },
};
</script>
