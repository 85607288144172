import { get } from '../../../../service/api';

export default {
  data() {
    return {
      validateInstallmentConfig: true,
      validateInstallmentConfigKoerich: true,
      steps: {
        GeneralDataProductForm: {
          step: 1,
          status: {},
          required: {
            code: true,
            name: true,
            'brand.name': true,
            productTypeId: true,
            'origin.description': true,
          },
          rules: {
            code: (value) => {
              if (!value) {
                return 'Campo `Código` é Obrigatório.';
              }
              return !!value.match(/^[-_A-z0-9]*$/g) || 'O Padrão é Inválido.';
            },
            name: (value) => (!!value) || 'Campo `Nome` é Obrigatório.',
            'brand.name': (value) => (!!value) || 'Campo `Marca` é Obrigatório.',
            'origin.description': (value) => (!!value) || 'Campo `Origem (CST)` é Obrigatório.',
            productTypeId: (value) => (!!value) || 'Campo `Tipo de produto` é Obrigatório.',
          },
        },
        SalesProductForm: {
          step: 2,
          status: {},
          required: {
            mediaUrl: true,
          },
          rules: {
            mediaUrl: (value) => {
              if (!value) {
                return true;
              }
              return !!value.includes('https://www.youtube.com/embed') || 'Campo `Media ULR` está invalido.';
            },
          },
        },
        DescriptionProductForm: {
          step: 3,
          status: {},
          required: {
            description: true,
          },
          rules: {
            description: (value) => (!!value) || 'Campo `Descrição` é Obrigatório.',
          },
        },
        PropertiesAndSpecificationProductForm: {
          step: 4,
          status: {},
          required: {
            warrantyTime: true,
          },
          rules: {
            warrantyTime: (value) => (!!value) || 'Campo `Garantia` é Obrigatório.',
          },
        },
        DimensionsAndWeightProductForm: {
          step: 5,
          status: {},
          required: {
            'packageInfo.width': true,
            'packageInfo.length': true,
            'packageInfo.height': true,
            'packageInfo.weight': true,
          },
          rules: {
            'packageInfo.width': (value) => (!!value) || 'Campo `Largura` é Obrigatório.',
            'packageInfo.length': (value) => (!!value) || 'Campo `Comprimento` é Obrigatório.',
            'packageInfo.height': (value) => (!!value) || 'Campo `Altura` é Obrigatório.',
            'packageInfo.weight': (value) => (!!value) || 'Campo `Peso` é Obrigatório.',
          },
        },
        FilesForm: {
          step: 6,
          status: {},
        },
        SKUVariationSubProductForm: {
          step: 7,
          status: {},
          required: {
            length: true,
            properties: true,
          },
          rules: {
            properties(skus) {
              let ref = true;
              if (skus) {
                skus.forEach((sku) => {
                  // eslint-disable-next-line no-plusplus
                  for (let i = 0; i < sku.properties.length; i++) {
                    const value = sku.properties[i];
                    if (value.critical && !value.value) {
                      ref = false;
                      sku.error = true;
                    }
                  }
                });
                return (!!ref) || 'Campo `Propriedades de produtos invalida` é Obrigatório.';
              }
              return 'Campo `Propriedades de produtos invalida` é Obrigatório.';
            },
            length: () => (this.form.skus.length > 0) || 'Campo `Largura` é Obrigatório.',
          },
        },
        RelatedProductForm: {
          step: 8,
          status: {},
          required: {},
          rules: {},
        },
        CardsAndBilletProductForm: {
          step: 9,
          status: {},
          required: {
            'installmentConfig.initialDate': false,
            'installmentConfig.finalDate': false,
            'installmentConfig.minValue': false,
            'installmentConfigKoerich.initialDate': false,
            'installmentConfigKoerich.finalDate': false,
            'installmentConfigKoerich.minValue': false,
          },
          rules: {
            'installmentConfig.initialDate': (value) => {
              this.setRequiredInstallmentConfigFields(value);
              if (!!value && (value?.installmentConfig?.maxInstallments > 0)) {
                return !(value.installmentConfig.initialDate && value.installmentConfig.initialDate.length > 0) ? 'Campo `Data Inicial` é Obrigatório.' : (new Date(value.installmentConfig.initialDate) <= new Date(value.installmentConfig.finalDate) || !value.installmentConfig.finalDate) || 'Campo `Data Inicial` deve ser inferior `Data Final`.';
              }
              return true;
            },
            'installmentConfig.finalDate': (value) => {
              this.setRequiredInstallmentConfigFields(value);
              if (!!value && (value?.installmentConfig?.maxInstallments > 0)) {
                return !(value.installmentConfig.finalDate && value.installmentConfig.finalDate.length > 0) ? 'Campo `Data final` é Obrigatório.' : (new Date(value.installmentConfig.initialDate) <= new Date(value.installmentConfig.finalDate) || !value.installmentConfig.initialDate) || 'Campo `Data Final` deve ser superior `Data Inicial`.';
              }
              return true;
            },
            'installmentConfig.minValue': (value) => {
              this.setRequiredInstallmentConfigFields(value);
              if (!!value && (value?.installmentConfig?.maxInstallments > 0)) {
                return (!!value.installmentConfig.minValue) || 'Campo `Valor mínimo parcelamento` é Obrigatório.';
              }
              return true;
            },
            'installmentConfigKoerich.initialDate': (value) => {
              this.setRequiredInstallmentConfigKoerichFields(value);
              if (!!value && (value?.installmentConfigKoerich?.maxInstallments > 0)) {
                return !(value.installmentConfigKoerich.initialDate && value.installmentConfigKoerich.initialDate.length > 0) ? 'Campo `Data Inicial` é Obrigatório.' : (new Date(value.installmentConfigKoerich.initialDate) <= new Date(value.installmentConfigKoerich.finalDate) || !value.installmentConfigKoerich.finalDate) || 'Campo `Data Inicial` deve ser inferior `Data Final`.';
              }
              return true;
            },
            'installmentConfigKoerich.finalDate': (value) => {
              this.setRequiredInstallmentConfigKoerichFields(value);
              if (!!value && (value?.installmentConfigKoerich?.maxInstallments > 0)) {
                return !(value.installmentConfigKoerich.finalDate && value.installmentConfigKoerich.finalDate.length > 0) ? 'Campo `Data final` é Obrigatório.' : (new Date(value.installmentConfigKoerich.initialDate) <= new Date(value.installmentConfigKoerich.finalDate) || !value.installmentConfigKoerich.initialDate) || 'Campo `Data Final` deve ser superior `Data Inicial`.';
              }
              return true;
            },
            'installmentConfigKoerich.minValue': (value) => {
              this.setRequiredInstallmentConfigKoerichFields(value);
              if (!!value && (value.installmentConfigKoerich?.maxInstallments > 0)) {
                return (!!value.installmentConfigKoerich.minValue) || 'Campo `Valor mínimo parcelamento` é Obrigatório.';
              }
              return true;
            },
          },
        },
        StampProductForm: {
          step: 10,
          status: {},
          required: {},
          rules: {
            description: (value) => {
              if (!!value && value.length < 1) {
                return 'Campo `Descrição` é Obrigatório.';
              }
              if (!!value && value.length > 20) {
                return 'Campo `Descrição` deve conter no máximo 20 caracteres.';
              }
              return true;
            },
          },
        },
        IndexingProductForm: {
          step: 11,
          status: {},
          required: {},
          rules: {},
        },
      },
    };
  },
  methods: {
    async getPossibleStatus(status) {
      const item = localStorage.getItem('current_user');
      const { sellerId } = item ? JSON.parse(item) : null;
      const { data } = await get(`ui-integrator/sellers/${sellerId}`);
      const items = this.getPossibleStatusSeller(status);

      return [
        { name: 'Publicado', value: 'published' },
        { name: 'Não publicado', value: 'unpublished' },
      ].filter((i) => items.includes(i.value));
    },
    getPossibleStatusAdmin(status) {
      switch (status) {
        case 'published':
          return ['published', 'unpublished'];
        case 'unpublished':
          return ['unpublished', 'published'];
        default:
          return [status];
      }
    },
    getPossibleStatusSeller(status) {
      switch (status) {
        case 'published':
          return ['published', 'unpublished'];
        case 'unpublished':
          return ['unpublished', 'published'];
        default:
          return [status];
      }
    },
    transformFormTosave(rowForm) {
      const form = JSON.parse(JSON.stringify(rowForm));
      if (form.mediaUrl === '') {
        form.mediaUrl = null;
      }
      if (!form.installmentConfig.maxInstallments) {
        form.installmentConfig = null;
      }
      if (!form.installmentConfigKoerich.maxInstallments) {
        form.installmentConfigKoerich = null;
      }
      if (!form.boletoConfig.initialDate) {
        form.boletoConfig = null;
      }
      for (let i = 0; i < form.skus.length; i += 1) {
        if (form.skus[i].price.list.toString().indexOf(',') !== -1 || form.skus[i].price.list.toString().indexOf('.') !== -1) {
          form.skus[i].price.list = form.skus[i].price.list.toString().replace(/[^0-9]/g, '');
        } else {
          // eslint-disable-next-line
          form.skus[i].price.list = form.skus[i].price.list.toString() + '00';
        }
        if (form.skus[i].price.sale.toString().indexOf(',') !== -1 || form.skus[i].price.sale.toString().indexOf('.') !== -1) {
          form.skus[i].price.sale = form.skus[i].price.sale.toString().replace(/[^0-9]/g, '');
        } else {
          // eslint-disable-next-line
          form.skus[i].price.sale = form.skus[i].price.sale.toString() + '00';
        }
      }
      form.status = this.form.status.value;
      form.relatedProductCodes = [];
      form.relatedProduct.forEach((value) => {
        form.relatedProductCodes.push(value.id);
      });

      return form;
    },
    setRequiredInstallmentConfigFields(value) {
      if (this.validateInstallmentConfig) {
        this.validateInstallmentConfig = false;
        this.steps.CardsAndBilletProductForm.required['installmentConfig.initialDate'] = true;
        this.steps.CardsAndBilletProductForm.required['installmentConfig.finalDate'] = true;
        this.steps.CardsAndBilletProductForm.required['installmentConfig.minValue'] = true;
      } else {
        this.validateInstallmentConfig = value && value.length > 0;
      }
    },
    setRequiredInstallmentConfigKoerichFields(value) {
      if (this.validateInstallmentConfigKoerich) {
        this.validateInstallmentConfigKoerich = false;
        this.steps.CardsAndBilletProductForm.required['installmentConfigKoerich.initialDate'] = true;
        this.steps.CardsAndBilletProductForm.required['installmentConfigKoerich.finalDate'] = true;
        this.steps.CardsAndBilletProductForm.required['installmentConfigKoerich.minValue'] = true;
      } else {
        this.validateInstallmentConfigKoerich = value && value.length > 0;
      }
    },
  },
};
