<template>
  <v-container class="box-content pa-0 pl-4 pr-4 pt-4" fluid>
    <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
    <FormStepController
      ref="formStep"
      v-if="!loading"
      :steps="steps"
      :canSubbmitEspecificatio="validCanSubmit"
      :blockSave="validSaveWithStatus"
      v-on:formCancel="formCancel"
      :form="form"
      title="Cadastrar Produto"
      @formSubbmit="formSubbmit"
      readOnly
    >
      <GeneralDataProductForm
        @validateRequired="validateRequired"
        :availableStatus="availableStatus"
        :validationRules="steps['GeneralDataProductForm'].rules"
        :form="form"
        title="Informações Gerais"
        readOnly
      />
      <SalesProductForm
        @validateRequired="validateRequired"
        :validationRules="steps['SalesProductForm'].rules"
        :form="form"
        title="Vendas"
        readOnly
      />
      <DescriptionProductForm
        @validateRequired="validateRequired"
        :validationRules="steps['DescriptionProductForm'].rules"
        :form="form"
        title="Descrição e Manual"
        readOnly
      />
      <PropertiesAndSpecificationProductForm
        @validateRequired="validateRequired"
        :validationRules="steps['PropertiesAndSpecificationProductForm']"
        :idProductType="form.productTypeId"
        :form="form"
        readOnly
      />
      <DimensionsAndWeightProductForm
        @validateRequired="validateRequired"
        :validationRules="steps['DimensionsAndWeightProductForm'].rules"
        :form="form"
        title="Dimensões e pesos"
        readOnly
      />
      <FilesForm
        @validateRequired="validateRequired"
        :validationRules="steps['FilesForm'].rules"
        :idProductType="form.productTypeId"
        :form="form"
        :mask="mask"
        readOnly
      />
      <SKUVariationSubProductForm
        @validateRequired="validateRequired"
        :validationRules="steps['SKUVariationSubProductForm'].rules"
        :idProductType="form.productTypeId"
        :form="form"
        :mask="mask"
        readOnly
      />
      <RelatedProductForm @validateRequired="validateRequired" :validationRules="steps['RelatedProductForm'].rules" :form="form" readOnly />
      <CardsAndBilletProductForm
        @validateRequired="validateRequired"
        :validationRules="steps['CardsAndBilletProductForm'].rules"
        :form="form"
        readOnly
      />
      <StampProductForm @validateRequired="validateRequired" :validationRules="steps['StampProductForm'].rules" :form="form" readOnly />
      <IndexingProductForm @validateRequired="validateRequired" :validationRules="steps['IndexingProductForm'].rules" :form="form" readOnly />
      <template #action>
        <v-btn
          depressed
          large
          v-if="form.status.value === 'draft'"
          @click="sendToReview"
          color="#00EB9B"
          class="mr-4 form_step_controller_action_button"
        >
          <span style="color:#f8f8f8">Encaminhar para aprovação</span>
        </v-btn>
        <v-btn
          depressed
          large
          v-if="form.status.value === 'approved'"
          @click="sendToAprove"
          color="#00EB9B"
          class="mr-4 form_step_controller_action_button"
        >
          <span style="color:#f8f8f8">Publicar o produto</span>
        </v-btn>
      </template>
    </FormStepController>
    <ConfirmDialog
      confirmActionLabel="Sim, emcaminhar"
      dialogType="warning"
      @confirm="confirmSendToReview"
      @close="sendToReviewModal = false"
      :item="form"
      :show-form="sendToReviewModal"
    >
      <template v-slot:message>
        <p>Após esta ação, este cadastro de produto será</p>
        <p>analisado, podendo ser aprovado ou reprovado.</p>
        <p>Durante este periodo o cadastro não podera ser</p>
        <p>editado nem excluido.</p>
        <br />
        <p>deseja mesmo encaminhar o cadastro para aprovação?.</p>
        <br />
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      confirmActionLabel="Sim, publicar"
      dialogType="warning"
      @confirm="confirmSendToPublish"
      @close="sendToPublishModal = false"
      :item="form"
      :show-form="sendToPublishModal"
    >
      <template v-slot:message>
        <p>Após esta ação, todas as informações do</p>
        <p>cadastro estarão visiveis nos canais de venda.</p>
        <br />
        <p>deseja mesmo aprovar todos os dados e</p>
        <p>publica este produto ?.</p>
        <br />
      </template>
    </ConfirmDialog>
  </v-container>
</template>

<script>
import FormStepController from '@/components/template/form/FormStepController';
import GeneralDataProductForm from '@/views/product/forms/GeneralDataProductForm';
import SalesProductForm from '@/views/product/forms/SalesProductForm';
import DescriptionProductForm from '@/views/product/forms/DescriptionProductForm';
import PropertiesAndSpecificationProductForm from '@/views/product/forms/PropertiesAndSpecificationProductForm';
import DimensionsAndWeightProductForm from '@/views/product/forms/DimensionsAndWeightProductForm';
import SKUVariationSubProductForm from '@/views/product/forms/SKUVariationSubProductForm';
import RelatedProductForm from '@/views/product/forms/RelatedProductForm';
import CardsAndBilletProductForm from '@/views/product/forms/CardsAndBilletProductForm';
import StampProductForm from '@/views/product/forms/StampProductForm';
import IndexingProductForm from '@/views/product/forms/IndexingProductForm';
import ConfirmDialog from '@/components/utils/dialog/ConfirmDialog';

import { get, post, put } from '../../service/api';
import notification from '@/service/notification';
import { routerPush } from '../../service/kplaceRouter';
import ProductFormMixin from './forms/mixins/ProductFormMixin';
import FormStepMixin from '../../components/template/form/mixins/FormStepMixin';
import { CstOptions } from '@/static-data/CstOptions';
import { ProdutoStatus } from '@/static-data/ProdutoStatusOptions';
import getProduct from '@/service/product/product-service';
import FilesForm from '@/views/product/forms/FilesForm';

const TemporaryDisableProductForm = true;

export default {
  components: {
    FilesForm,
    GeneralDataProductForm,
    SalesProductForm,
    DescriptionProductForm,
    PropertiesAndSpecificationProductForm,
    DimensionsAndWeightProductForm,
    SKUVariationSubProductForm,
    RelatedProductForm,
    CardsAndBilletProductForm,
    StampProductForm,
    IndexingProductForm,
    FormStepController,
    ConfirmDialog,
  },
  computed: {
    validCanSubmit() {
      const { form } = this;
      return !!(
        form.status.value === 'draft' &&
        form.code &&
        form.name &&
        form.status &&
        this.steps.SalesProductForm.rules.mediaUrl(form.mediaUrl) !== 'Campo `Media ULR` está invalido.'
      );
    },
    validSaveWithStatus() {
      if (this.startStatus === 'published') {
        return false;
      }
      return !!['pending-review', 'in-review', 'approved', 'published'].includes(this.form.status.value);
    },
  },
  mixins: [ProductFormMixin, FormStepMixin],
  methods: {
    async sendToReview() {
      const response = (data) => {
        if (data?.response?.status > 299) {
          notification(`${data?.response?.data?.message}`, 'error');
        } else {
          this.sendToReviewModal = true;
        }
      };
      this.save(response);
    },
    async sendToAprove() {
      this.sendToPublishModal = true;
    },
    formCancel() {
      routerPush('/product');
    },
    save(response) {
      const form = this.transformFormTosave(this.form);
      if (form.id) {
        form.lastModifiedBy = this.$store?.state?.main?.currentUser?.username;
        form.createdBy = form.createdBy || this.$store?.state?.main?.currentUser?.username;
        put(`ui-integrator/products/${form.id}`, form).then(response);
      } else {
        form.createdAt = new Date();
        form.createdBy = this.$store?.state?.main?.currentUser?.username;
        form.lastModifiedBy = this.$store?.state?.main?.currentUser?.username;
        post('ui-integrator/products/', form).then(response);
      }
    },
    formSubbmit() {
      const response = (data) => {
        if (data?.response?.status > 299) {
          notification(`${data?.response?.data[0].message}`, 'error');
        } else {
          this.form.id = data.data.id;
          notification('O cadastro do produto foi salvo com sucesso', 'success');
          routerPush('/product');
        }
      };
      this.save(response);
    },
    async confirmSendToPublish() {
      try {
        this.loading = true;
        post(`ui-integrator/products/${this.item.code}/publish`).then((response) => {
          if (response?.response?.status > 299) {
            notification(`${response?.response?.data?.message}`, 'error');
          } else {
            routerPush('/product');
          }
        });
      } finally {
        this.loading = false;
      }
    },
    async confirmSendToReview() {
      try {
        this.loading = true;
        post(`ui-integrator/products/${this.item.code}/send-to-review`).then((response) => {
          if (response?.response?.status > 299) {
            notification(`${response?.response?.data?.message}`, 'error');
          } else {
            routerPush('/product');
          }
        });
      } finally {
        this.loading = false;
      }
    },
  },
  data() {
    return {
      cstOptions: CstOptions,
      loading: false,
      sendToReviewModal: false,
      sendToPublishModal: false,
      saveWithInvalidForm: true,
      form: {
        code: null,
        name: null,
        description: null,
        warrantyTime: null,
        productTypeVersion: null,
        brand: {
          name: null,
        },
        installmentConfig: {
          installments: [],
        },
        discountsByPaymentType: [],
        seoMetaInfo: {},
        installmentConfigKoerich: {
          installments: [],
        },
        boletoConfig: {},
        manual: [],
        properties: [],
        buyTogether: [],
        relatedProductCodes: [],
        relatedProduct: [],
        productTypeId: null,
        modalType: null,
        sellerServices: [],
        status: { name: 'Em rascunho', value: 'draft' },
        productKit: false,
        storePickupOnly: false,
        allowedSale: false,
        salesPotential: null,
        orderLimit: null,
        releaseDate: null,
        mediaUrl: null,
        width: null,
        height: null,
        length: null,
        weight: null,
        packageInfo: {
          width: null,
          height: null,
          length: null,
          weight: null,
        },
        marketplaceSellerName: null,
        marketplaceDeliveryName: null,
        origin: null,
        stamps: [],
        skus: [],
        files: [],
      },
      status: ProdutoStatus,
      startStatus: null,
      availableStatus: [],
      mask: {
        toMask: true,
        patterns: [
          { from: '^(0*)$', to: ',00' },
          { from: '^(0*)(\\d*),(\\d{1})$', to: '$2,$30' },
          { from: '^(0*)(\\d*),(\\d{2})\\d*$', to: '$2,$3' },
          { from: '^(0*)(\\d+)$', to: '$2,00' },
          { from: '^(0*)(\\d+)(\\d{3}),(\\d{2})$', to: '$2.$3,$4' },
          { from: '^(0*)(\\d+)(\\d{3})[.](\\d{3}),(\\d{2})$', to: '$2.$3.$4,$5' },
          { from: '^(0*)(\\d+)(\\d{3})[.](\\d{3})[.](\\d{3}),(\\d{2})$', to: '$2.$3.$4.$5,$6' },
          { from: '^(0*)(\\d+)(\\d{3})[.](\\d{3})[.](\\d{3})[.](\\d{3}),(\\d{2})$', to: '$3.$4.$5.$6,$7' },
          { from: '^,00$', to: '0,00' },
        ],
      },
    };
  },
  async mounted() {
    try {
      this.loading = true;
      if (this.$route.params.id && this.$route.params.id !== 'undefined') {
        const data = await getProduct(this.$route.params.id);
        // eslint-disable-next-line prefer-destructuring
        const status = this.status.filter((value) => value.value.toLowerCase() === data.status.toLowerCase())[0];
        this.startStatus = status?.value?.toLowerCase();
        Object.keys(this.form).forEach((key) => {
          if (!data[key]) {
            data[key] = this.form[key];
          } else if (data[key] instanceof Array && data[key].length === 0) {
            data[key] = this.form[key];
          }
        });
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < data.stamps.length; i++) {
          data.stamps[i].id = i + 1;
        }
        if (data.origin?.id) {
          // eslint-disable-next-line prefer-destructuring
          data.origin = this.cstOptions.filter((cst) => cst.id === data.origin?.id)[0];
        }
        this.form = data;
        const arkeys = Object.keys(this.steps);
        for (let i = 0; i < arkeys.length; i += 1) {
          const requireds = Object.keys(this.steps[arkeys[i]].required);
          this.steps[arkeys[i]].status = { valid: true, oppened: true };
          // eslint-disable-next-line no-plusplus
          for (let i1 = 0; i1 < requireds.length; i1++) {
            const required = requireds[i1];
            let item;
            const { rules } = this.steps[arkeys[i]];
            if (required === 'properties') {
              // eslint-disable-next-line no-multi-assign
              item = this.steps[arkeys[i]].required[required] = rules[required](this.form.skus) === true;
            } else if (required.includes('.')) {
              this.steps[arkeys[i]].required[required] = !(rules[required](this.form[required.split('.')[0]][required.split('.')[1]]) === true);
              item = rules[required](this.form[required.split('.')[0]][required.split('.')[1]]);
            } else {
              this.steps[arkeys[i]].required[required] = !(rules[required](this.form[required]) === true);
              item = rules[required](this.form[required]);
            }
            if (!item || typeof item === 'string') {
              this.steps[arkeys[i]].status = { valid: false, oppened: true };
            }
          }
        }
      } else {
        this.startStatus = 'draft';
      }
      this.availableStatus = await this.getPossibleStatus(this.startStatus);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style>
.box-content {
  background-color: rgb(237, 237, 237);
  height: calc(100% - 80px);
  margin-top: 80px;
}
</style>
